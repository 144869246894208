import React from "react";
import Navbar from "../sections/Navbar/Navbar";
import InternalCoachingHero from "../sections/InternalCoachingHero.";
import CaseStudies from "../sections/CaseStudies";
import Faq from "../sections/FaqFr";
import Footer from "../sections/Footer";
// @ts-ignore
import Seo from "../utils/seo";
import ExecutiveCoachingHero from "../sections/ExecutiveCoachingHeroFr";
import Executives from "../sections/ExecutivesFr";
import ExecutiveCoaches from "../sections/ExecutiveCoachesFr";
import What from "../sections/WhatFr";
// @ts-ignore
import Layout from "../layouts";
import Leaders from "../sections/LeadersFr";
const ExecutiveCoaching = () => {
  return (
    <>
      <Seo
        title="Coaching exécutif | Boostez votre leadership avec Coachello" description="Développez votre potentiel de leadership avec notre coaching exécutif. Optimisez vos compétences et réussissez en affaires."
        // image={seoImg}
      />

      <Layout>
      <ExecutiveCoachingHero />
      <Executives />
      <ExecutiveCoaches />
      <What />
      <Leaders />
      <Faq />
       </Layout>
    </>
  );
};

export default ExecutiveCoaching;
